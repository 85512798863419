import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/content/blog/engineer/mermaid/render-architecture/index.md";
import React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ShareButton from "../components/social-share/social-share";
import PostMeta from "../components/post-meta";
import TOC from "../components/blog-toc";
import GoogleAdsense from "../components/google-adsense";
import "./blog-post.scss";
const Header = _ref => {
  let {post} = _ref;
  return React.createElement("header", null, React.createElement("h1", {
    className: "title"
  }, post.frontmatter.title), React.createElement(PostMeta, {
    post: post
  }));
};
const PermalinkButton = _ref2 => {
  let {direction, nextpost} = _ref2;
  const text = direction === "prev" ? "<< prev" : "next >>";
  return React.createElement(React.Fragment, null, React.createElement(Tooltip, {
    title: nextpost.frontmatter.title,
    arrow: true
  }, React.createElement(Button, {
    variant: "outlined",
    color: "primary",
    size: "small",
    href: nextpost.fields.slug
  }, text)), React.createElement("p", {
    className: "next-title"
  }, " ", nextpost.frontmatter.title));
};
const Footer = _ref3 => {
  let {context, title, url, social} = _ref3;
  const {previous, next} = context;
  const nextPage = React.createElement("ul", {
    className: "next-page"
  }, React.createElement("li", null, previous && React.createElement(PermalinkButton, {
    direction: "prev",
    nextpost: previous
  })), React.createElement("li", null, next && React.createElement(PermalinkButton, {
    direction: "next",
    nextpost: next
  })));
  return React.createElement(React.Fragment, null, React.createElement("nav", {
    className: "footer nav"
  }, React.createElement(ShareButton, {
    title: title,
    url: url,
    twitterHandle: social.twitter
  }), nextPage), React.createElement(GoogleAdsense, null));
};
const BlogPostTemplate = _ref4 => {
  let {data, pageContext, children, location} = _ref4;
  const post = data.mdx;
  const social = data.site.siteMetadata.social;
  const title = post.frontmatter.title;
  return React.createElement(Layout, {
    location: location
  }, React.createElement(SEO, {
    title: title,
    description: post.excerpt
  }), React.createElement("div", {
    className: "blog-post-wrapper"
  }, React.createElement("article", {
    className: "blog-post"
  }, React.createElement(Header, {
    post: post
  }), React.createElement("section", {
    itemProp: "articleBody"
  }, children), React.createElement(Footer, {
    context: pageContext,
    title: title,
    url: location.href,
    social: social
  })), React.createElement("aside", null, React.createElement(TOC, {
    links: post.tableOfContents
  }))));
};
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
const pageQuery = "2959389897";
